import api from '@/api'
import DateService from '@/helpers/DateService'
import Vue from 'vue'

import * as Utils from '../../helpers/Utils'

let Q = require('q')

const state = {
  medios: [],

  posSinMedios: [],

  clasesMedio: [],
  categoriasMedio: [],
  tiposMedio: [],
  zonas: [],

  emergencias: [],

  estadosMedio: []
}

const getters = {
  medios: (state) => {
    // console.log('MEDIOS', state.medios.filter(x => x.MEDIO === '113'))
    return state.medios
  },
  posSinMedios: (state) => state.posSinMedios,

  getMedioByID: (state) => (idMedio) => {
    let medio = null

    if (idMedio) {
      medio = state.medios.find(x => x.ID_MEDIO.equalsIgnoreCase(idMedio))
    }
    return medio
  },

  clasesMedio: (state) => state.clasesMedio,

  categoriasMedio: (state) => state.categoriasMedio,

  tiposMedio: (state) => state.tiposMedio,

  zonas: (state) => state.zonas,

  emergencias: (state) => state.emergencias,

  estados: (state) => state.estadosMedio
}

const mutations = {
  SET_POS_SIN_MEDIOS (state, posSinMedios) {
    state.posSinMedios = posSinMedios
  },

  SET_MEDIOS (state, medios) {
    state.medios = medios
  },

  SET_CLASES_MEDIO (state, clasesMedio) {
    state.clasesMedio = clasesMedio
  },

  SET_CATEGORIAS_MEDIO (state, categoriasMedio) {
    state.categoriasMedio = categoriasMedio
  },

  SET_TIPOS_MEDIO (state, tiposMedio) {
    state.tiposMedio = tiposMedio
  },

  SET_ZONAS_MEDIO (state, zonas) {
    state.zonas = zonas
  },

  SET_EMERGENCIAS (state, emergencias) {
    state.emergencias = emergencias
  },

  SOCKET_updatePositionMedio (state, data) {
    if (data) {
      for (let i = 0; i < state.medios.length; i++) {
        let medio = state.medios[i]

        let positionDispositivo = medio.MEDIO_DISPOSITIVOS.findIndex(x => x.ID_GEO_DISPOSITIVO.equalsIgnoreCase(data.ID_GEO_DISPOSITIVO))
        if (positionDispositivo > -1) {
          let dispositivo = medio.MEDIO_DISPOSITIVOS[positionDispositivo]

          dispositivo.LATITUD = data.LATITUD
          dispositivo.LONGITUD = data.LONGITUD
          dispositivo.ALTITUD = data.ALTITUD
          dispositivo.BATERIA = data.BATERIA
          dispositivo.FECHA = data.FECHA

          // Actualizar ULT_POSICION
          if (DateService.parseDate(dispositivo.FECHA) > DateService.parseDate(medio.ULT_POSICION.FECHA)) {
            medio.ULT_POSICION = dispositivo
          }

          Vue.set(state.medios, i, medio)
        }
      }
    }
  },
  SOCKET_addMedio (state, data) {
    state.medios.push(data)
    this.notify('Nuevo medio', `Se ha añadido el medio ${data.MEDIO}`)
  },
  SOCKET_updateMedio (state, data) {
    let positionMedio = state.medios.findIndex(x => x.ID_MEDIO.equalsIgnoreCase(data.ID_MEDIO))

    if (positionMedio !== -1) {
      state.medios[positionMedio] = data
      this.notify('Medio actualizado', `Se ha actualizado el medio ${data.MEDIO}`)
    }
    Vue.set(state.medios, positionMedio, data)
  },
  SOCKET_disableMedio (state, data) {
    let positionMedio = state.medios.findIndex(x => x.ID_MEDIO.equalsIgnoreCase(data.ID_MEDIO))

    if (positionMedio !== -1) {
      state.medios.splice(positionMedio, 1)
      this.notify('Medio deshabilitado', `Se ha deshabilitado el medio ${data.MEDIO}`)
    }
  },

  SOCKET_updateEstadoMedio (state, data) {
    let mediosArray = data.SECTORES.map(sector => sector.MEDIOS.map(medio => medio.ID_MEDIO)).flat()

    mediosArray.forEach(medio => {
      let index = state.medios.findIndex(x => x.ID_MEDIO.equalsIgnoreCase(medio))
      if (state.medios[index].ESTADO !== 0) {
        state.medios[index].ESTADO = 0
      }
    })
  },

  SOCKET_emergenciaMedio (state, data) {
    let positionMedio = state.medios.findIndex(x => x.ID_MEDIO.equalsIgnoreCase(data.ID_MEDIO))

    if (positionMedio !== -1) {
      let medio = state.medios[positionMedio]

      medio.EN_EMERGENCIA = data.IS_EMERGENCIA
      // TODO: Vue.set(state.medios, positionMedio, medio)

      if (data.IS_EMERGENCIA) {
        let emergencia = { ID_MEDIO: data.ID_MEDIO, LATITUD: data.LATITUD, LONGITUD: data.LONGITUD, FECHA_INICIO: data.FECHA, FECHA_FIN: null }
        state.emergencias.push(emergencia)
      } else {
        let indexEmergencia = state.emergencias.findIndex(x => x.ID_MEDIO === state.ID_MEDIO)
        state.emergencias.splice(indexEmergencia, 1)
      }

      let title = data.IS_EMERGENCIA ? 'EMERGENCIA' : 'Emergencia finalizada'
      let msg = data.IS_EMERGENCIA ? `El medio ${medio.MEDIO} ha notificado una emergencia` : `La emergencia del medio ${medio.MEDIO} ha sido finalizada`
      this.notify(title, msg, data.IS_EMERGENCIA ? 'error' : 'success')
    }
  },
  SET_ESTADOS_MEDIO (state, estadosMedio) {
    state.estadosMedio = estadosMedio
  }
}

const actions = {
  async fetchMedios ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.medio.getMedios()

      const responseSinMedios = await api.medio.getPosActualesSinMedio()
      if (Utils.getIsCancelRefresh()) {
        deferred.resolve()
        return
      }

      // console.log('MEDIOS', response.data.filter(x => x.MEDIO === '113'))
      commit('SET_MEDIOS', response.data)

      commit('SET_POS_SIN_MEDIOS', responseSinMedios.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async fetchClasesMedio ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.medio.getClasesMedio()
      commit('SET_CLASES_MEDIO', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async fetchCategoriasMedio ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.medio.getCategoriasMedio()
      commit('SET_CATEGORIAS_MEDIO', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async fetchTiposMedio ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.medio.getTiposMedio()
      commit('SET_TIPOS_MEDIO', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async fetchZonasMedio ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.medio.getZonasMedio()
      commit('SET_ZONAS_MEDIO', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async addMedio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.medio.addMedio(data)

      if (response.status === 200) {
        commit('SOCKET_addMedio', data)
        this._vm.$socket.emit('addMedio', data)
      }

      deferred.resolve()
    } catch (err) {
      this.notify('Error al editar medio', 'El nombre del medio ya existe', 'error')
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async updateMedio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.medio.updateMedio(data)

      if (response.status === 200) {
        commit('SOCKET_updateMedio', data)
        this._vm.$socket.emit('updateMedio', data)
      }

      deferred.resolve()
    } catch (err) {
      this.notify('Error al editar medio', 'El nombre del medio ya existe', 'error')
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async deleteMedio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.medio.deleteMedio(data)

      if (response.status === 200) {
        commit('SOCKET_deleteMedio', data)
        this._vm.$socket.emit('deleteMedio', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async disableMedio ({ commit, dispatch }, data) {
    let deferred = Q.defer()
    try {
      let dataCopy = JSON.parse(JSON.stringify(data))
      dataCopy.HABILITADO = false
      dataCopy.ESTADO_MEDIO = state.estadosMedio.filter(x => x.NOMBRE === 'DESHABILITADO' && x.ID_MEDIO_CLASE === dataCopy.ID_MEDIO_CLASE)

      const response = await api.medio.disableMedio(dataCopy)
      if (response.status === 200) {
        commit('SOCKET_updateMedio', dataCopy)
        this._vm.$socket.emit('updateMedio', dataCopy)
      }
      dispatch('fetchMedios')
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },

  async enableMedio ({ commit, dispatch }, data) {
    let deferred = Q.defer()
    try {
      let dataCopy = JSON.parse(JSON.stringify(data))

      dataCopy.HABILITADO = true
      dataCopy.ESTADO_MEDIO = state.estadosMedio.filter(x => x.NOMBRE === 'ACTIVO' && x.ID_MEDIO_CLASE === dataCopy.ID_MEDIO_CLASE)

      const response = await api.medio.enableMedio(dataCopy)
      if (response.status === 200) {
        commit('SOCKET_updateMedio', dataCopy)
        this._vm.$socket.emit('updateMedio', dataCopy)
      }
      dispatch('fetchMedios')
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },

  async deactivateMedio ({ commit, dispatch }, data) {
    let deferred = Q.defer()
    try {
      let dataCopy = JSON.parse(JSON.stringify(data))
      dataCopy.ACTIVO = false
      const response = await api.medio.updateMedio(dataCopy)
      if (response.status === 200) {
        commit('SOCKET_updateMedio', dataCopy)
        this._vm.$socket.emit('updateMedio', dataCopy)
      }
      dispatch('fetchMedios')
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },

  async activateMedio ({ commit, dispatch }, data) {
    let deferred = Q.defer()
    try {
      let dataCopy = JSON.parse(JSON.stringify(data))
      dataCopy.ACTIVO = true
      const response = await api.medio.updateMedio(dataCopy)
      if (response.status === 200) {
        commit('SOCKET_updateMedio', dataCopy)
        this._vm.$socket.emit('updateMedio', dataCopy)
      }
      dispatch('fetchMedios')
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },

  async updateEstadoMedio ({ commit }, data) {
    let deferred = Q.defer()

    try {
      commit('SOCKET_updateEstadoMedio', data)
      this._vm.$socket.emit('updateEstadoMedio', data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async fetchEmergencias ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.medio.getEmergencias()
      commit('SET_EMERGENCIAS', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async setEmergenciaMedio ({ commit }, data) { // TODO: testear
    let deferred = Q.defer()

    try {
      const response = await api.medio.setEmergenciaMedio(data)

      if (response.status === 200) {
        commit('SOCKET_emergenciaMedio', data)
        this._vm.$socket.emit('emergenciaMedio', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async fetchEstadosMedio ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.medio.getEstadosMedios()
      commit('SET_ESTADOS_MEDIO', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
