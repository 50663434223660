<template>
  <v-dialog v-model="show" persistent max-width="480px">
    <v-card>
      <v-card-title class="pt-0 px-0">
        <v-toolbar color="primaryLight" dark dense>
          <v-toolbar-title>Editar estados</v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text id="estadosData" class="my-5">
        <v-row v-for="(estado, i) in estadosIncendio" :key="i" style="height: 60px;" class="mb-2 align-center justify-center">
          <v-col cols="auto" style="width: 140px;">
            <v-chip class="d-flex justify-center align-center" :color="estado.COLOR">
              {{ estado.INCENDIO_ESTADO }}
            </v-chip>
          </v-col>
          <v-col cols="auto">
            <v-form>
              <!--<v-text-field con esto no hay :min ni :max-->
              <vx-date-text-edit
                v-model="fechaEstados[i]"
                label="Fecha"
                v-facade="mask"
                icon="esri-icon-calendar"
                @focus="setCurrentDate(i)"
                :min="dateMin[i]"
                :max="dateMax[i]"
                :between="dateBetween[i]"
                :disabled="disabledStates[i]"
                not-future
                ref="form"
              />
            </v-form>
          </v-col>

          <v-col cols="auto">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn icon color="red" v-bind="attrs" v-on="on" :disabled="disabledStates[i]" @click="removeFecha(i)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Eliminar fecha salida.</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-checkbox v-model="autoComplete" label="Autocompletar" />
        <v-spacer />
        <v-btn color="red" text @click="cerrar">Cerrar</v-btn>
        <v-btn color="green" text :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    show: Boolean,
    incendio: Object
  },

  data: () => ({
    mask: '##/##/#### ##:##',

    sortedEstados: [],
    disabledStates: [],
    textFocus: undefined,
    autoComplete: true,
    fechaEstados: [],
    dateMin: [],
    dateMax: [],
    dateBetween: []
  }),

  watch: {
    show () {
      if (this.show) {
        this.textFocus = undefined
        this.getFechaEstados()
      }
    },
    fechaEstados (newVal, oldVal) {
      this.fechaEstadosChange()
      if (this.textFocus || this.textFocus === 0) {
        if (this.textFocus === 5) {
          if (this.fechaEstados[this.textFocus]) {
            this.disableSubsequentDates(0)
          } else {
            this.enableSubsequentDates(0)
          }
        } else if (!this.fechaEstados[this.textFocus]) {
          this.disableSubsequentDates(this.textFocus)
        } else if (!this.fechaEstados[5]) {
          this.enableSubsequentDates(this.textFocus)
        }
      }
    }
  },

  computed: {
    ...mapGetters('incendio', ['tiposIncendioEstado']),

    estadosIncendio () {
      return this.tiposIncendioEstado
    },

    isValid () {
      if (!this.fechaEstados[0] || this.fechaEstados[0] === '') {
        return false
      }

      // Comprobamos que no haya huecos
      let a = this.fechaEstados.findIndex(x => x === '' || !x)
      let b = this.fechaEstados.findLastIndex(x => x && x !== '')

      if (b > a && b !== 5) {
        return false
      }

      for (let i = 0; i < this.fechaEstados.length; i++) {
        if (this.fechaEstados[i] && this.fechaEstados[i] !== '') {
          const fechaFueraLimites = this.$date.parseDate(this.dateMin[i]) > this.$date.parseDate(this.fechaEstados[i]).toDate() || this.$date.parseDate(this.dateMax[i]) < this.$date.parseDate(this.fechaEstados[i]).toDate()
          const fechaLongitud = this.fechaEstados[i].length !== this.mask.length
          if (fechaLongitud || fechaFueraLimites) {
            return false
          }
        }
      }

      return true
    }
  },

  methods: {
    disableSubsequentDates (pos) {
      for (let i = this.estadosIncendio.length - 2; i > pos; i--) {
        this.disabledStates[i] = true
      }
    },
    enableSubsequentDates (pos) {
      for (let i = this.estadosIncendio.length - 2; i > pos; i--) {
        if (this.fechaEstados[i] || (!this.fechaEstados[i] && this.fechaEstados[i - 1])) {
          this.disabledStates[i] = false
        }
      }
    },

    fechaEstadosChange () {
      for (let i = 0; i < this.estadosIncendio.length; i++) {
        this.getMinDate(i)
        this.getMaxDate(i)
        this.getBetweenDate(i)
      }
    },

    removeFecha (pos) {
      this.textFocus = pos
      this.$set(this.fechaEstados, this.textFocus, null)
      this.getMinDate(this.textFocus)
      this.getMaxDate(this.textFocus)
      this.getBetweenDate(this.textFocus)
    },

    getFechaEstados () {
      if (this.incendio) {
        this.fechaEstados = new Array(this.estadosIncendio.length).fill(null)
        this.sortedEstados = [...this.incendio.ESTADOS]
          .sort((estado1, estado2) => {
            return estado1.TIPO_INCENDIO_ESTADO.VALUE - estado2.TIPO_INCENDIO_ESTADO.VALUE
          })

        this.sortedEstados.forEach(estado => {
          this.fechaEstados[estado.TIPO_INCENDIO_ESTADO.VALUE] = this.$date
            .parseDate(estado.FECHA)
            .format('DD/MM/YYYY HH:mm')
        })
      }

      this.disabledStates = new Array(this.estadosIncendio.length).fill(false)

      for (let i = 0; i < this.estadosIncendio.length; i++) {
        if (i !== 0 && i !== 5 && !this.fechaEstados[i - 1]) {
          this.disabledStates[i] = true
        }
        if (this.fechaEstados[5] && i !== 0 && i !== 5) {
          this.disabledStates[i] = true
        }
      }
    },

    setCurrentDate (pos) {
      this.textFocus = pos
      if (pos === 5) {
        for (let i = 4; i > 0; i--) {
          if (this.fechaEstados[i]) {
            this.textFocus = i
            this.$set(this.fechaEstados, i, null)
            this.fechaEstadosChange()
          }
        }
        this.textFocus = 5
        this.dateBetween = []
      }

      this.getMinDate(pos)
      this.getMaxDate(pos)
      this.getBetweenDate(pos)
      if ((!this.fechaEstados[pos] || this.fechaEstados[pos] === '') && this.autoComplete) {
        const currentDate = this.$date.currentDate()
        this.$set(this.fechaEstados, pos, currentDate)
      }
    },

    getMinDate (pos) {
      if (pos === 5) {
        this.dateMin[pos] = this.fechaEstados[0]
          ? this.$date
            .parseDate(this.fechaEstados[0])
            .add(1, 'minute')
            .format('DD/MM/YYYY HH:mm')
          : null
      } else {
        this.dateMin[pos] = this.fechaEstados[pos - 1]
          ? this.$date
            .parseDate(this.fechaEstados[pos - 1])
            .add(1, 'minute')
            .format('DD/MM/YYYY HH:mm')
          : null
      }
    },

    getMaxDate (pos) {
      if (this.fechaEstados[5] && this.fechaEstados[5] !== '') {
        return
      }
      if (pos === 0 && !this.fechaEstados[pos + 1]) {
        this.dateMax[pos] = this.fechaEstados[5]
          ? this.$date
            .parseDate(this.fechaEstados[5])
            .add(-1, 'minute')
            .format('DD/MM/YYYY HH:mm')
          : null
      } else {
        this.dateMax[pos] = this.fechaEstados[pos + 1]
          ? this.$date
            .parseDate(this.fechaEstados[pos + 1])
            .add(-1, 'minute')
            .format('DD/MM/YYYY HH:mm')
          : null
      }
    },

    getBetweenDate (pos) {
      if (this.fechaEstados[5] && this.fechaEstados[5] !== '') {
        return
      }

      if (this.textFocus === 0 && !this.fechaEstados[pos + 1]) {
        this.dateBetween[pos] = this.fechaEstados[5]
          ? this.$date
            .parseDate(this.fechaEstados[5])
            .add(-1, 'minute')
            .format('DD/MM/YYYY HH:mm')
          : null
      } else {
        this.dateBetween[pos] = this.fechaEstados[pos + 1]
          ? this.$date
            .parseDate(this.fechaEstados[pos + 1])
            .add(-1, 'minute')
            .format('DD/MM/YYYY HH:mm')
          : null
      }
    },

    aceptar () {
      if (!this.isValid) {
        return
      }

      if (this.incendio) {
        let incendioEstadosAscendente = new Array(this.estadosIncendio.length).fill(null)
        const sortedEstados = [...this.incendio.ESTADOS].sort((estado1, estado2) => {
          return estado1.TIPO_INCENDIO_ESTADO.VALUE - estado2.TIPO_INCENDIO_ESTADO.VALUE
        })

        sortedEstados.forEach(estado => {
          incendioEstadosAscendente[estado.TIPO_INCENDIO_ESTADO.VALUE] = estado
        })

        // Si existe falsa alarma, la eliminamos la primera
        if (incendioEstadosAscendente[5] && (!this.fechaEstados[5] || this.fechaEstados[5] === '')) {
          let data = {
            ID_INCENDIO_ESTADO: incendioEstadosAscendente[5].ID_INCENDIO_ESTADO,
            ID_INCENDIO: incendioEstadosAscendente[5].ID_INCENDIO,
            NUEVO_ESTADO: incendioEstadosAscendente[0].TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO
          }

          this.$store.dispatch('incendio/deleteEstadoIncendio', data)
          setTimeout(() => {}, 1000)
        }
        for (let i = 0; i < this.estadosIncendio.length; i++) {
          if (incendioEstadosAscendente[i] && this.fechaEstados[i] && this.$date.parseDate(incendioEstadosAscendente[i].FECHA).format('DD/MM/YYYY HH:mm') !== this.$date.parseDate(this.fechaEstados[i]).format('DD/MM/YYYY HH:mm')) {
            let data = {
              ID_INCENDIO: incendioEstadosAscendente[i].ID_INCENDIO,
              ID_TIPO_INCENDIO_ESTADO: incendioEstadosAscendente[i].ID_TIPO_INCENDIO_ESTADO,
              CODIGO_TIPO_ESTADO: incendioEstadosAscendente[i].TIPO_INCENDIO_ESTADO.VALUE,
              FECHA: this.$date.parseDate(this.fechaEstados[i])
            }

            this.$store.dispatch('incendio/editEstadoIncendio', data)
            this.$emit('redrawIncendio')
          }

          if (!incendioEstadosAscendente[i] && this.fechaEstados[i]) {
            let estadoIncendio = {
              ID_INCENDIO_ESTADO: this.$uuid.createUUID(),
              ID_INCENDIO: this.incendio.ID_INCENDIO,
              ID_TIPO_INCENDIO_ESTADO: this.estadosIncendio[i].ID_TIPO_INCENDIO_ESTADO,
              FECHA: this.$date.parseDate(this.fechaEstados[i]),
              TIPO_INCENDIO_ESTADO: this.estadosIncendio[i]
            }
            incendioEstadosAscendente[i] = estadoIncendio
            this.$store.dispatch('incendio/addEstadoIncendio', estadoIncendio).then(() => {
            }).catch((e) => {
              this.$notify({
                title: 'Cambio de estado',
                text: `Error al cambiar el estado del incendio a ${this.estadosIncendio[i].INCENDIO_ESTADO}`,
                type: 'error'
              })
            })
          }
          // No se elimina 'Inicio' (no se puede) ni 'Falsa alarma' (se elimina antes del bucle)
          if (i !== 0 && i !== 5 && incendioEstadosAscendente[i] && (!this.fechaEstados[i] || this.fechaEstados[i] === '')) {
            let length = this.fechaEstados.filter(fecha => fecha !== '' && fecha !== null).length
            let newEstado = length - 1

            let data = {
              ID_INCENDIO_ESTADO: incendioEstadosAscendente[i].ID_INCENDIO_ESTADO,
              ID_INCENDIO: incendioEstadosAscendente[i].ID_INCENDIO,
              NUEVO_ESTADO: incendioEstadosAscendente[newEstado].TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO
            }

            this.$store.dispatch('incendio/deleteEstadoIncendio', data)
            setTimeout(() => {}, 1000)
          }
        }
      }

      this.cerrar()
    },

    cerrar () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped></style>
