
export const downloadJSON2CSV = async function (headers, data) {
  let str = ''

  let line = []
  headers.forEach(element => {
    line.push('"' + element.text + '"')
  })

  str += line.join(';')
  str += '\r\n'

  data.forEach(excelRow => {
    let line = []

    headers.forEach(header => {
      let properties = header.value.split('.')
      let valor = excelRow
      for (const property of properties) {
        valor = valor[property]
      }
      switch (valor) {
        case null:
        case undefined:
          valor = ''
          break
        case true:
          valor = 'Sí'
          break
        case false:
          valor = 'No'
          break
      }
      line.push('"' + valor + '"')
    })
    str += line.join(';')
    str += '\r\n'
  })

  window.open('data:text/csv;charset=iso-8859-15,' + encodeURIComponent(str))
}
